<template>
	<div class="snHome">
		<!-- 头部 -->
        <headers :haveEmpty="true"></headers>
        <!-- 面包屑 -->
        <crumbs :listData="listData"></crumbs>
		<!-- banner -->
        <commonBanner :bannerObj="bannerObj"></commonBanner>
		<!-- 列表部分 -->
		<div class="ListBox">
			<div class="leftBox">
				<template v-if="index % 2 == 0" v-for="(item,index) in listDatas" >
					<div class="items" :key="index">
						<div class="imgs">
							<img :src="item.coverUrl" alt="">
						</div>
						<div class="info">
							<div class="title">{{item.describe}}</div>
							<div class="text">{{item.content}}</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="items" :key="index">
						<div class="info">
							<div class="title">{{item.describe}}</div>
							<div class="text">{{item.content}}</div>
						</div>
						<div class="imgs">
							<img :src="item.coverUrl" alt="">
						</div>
					</div>
				</template>
				<div class="pages">
                    <el-pagination
                        class="pagination"
                        layout="prev, pager, next"
                        :total="totalNum"
                        :current-page="pageNum"
                        :page-size="pageSize"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
			</div>
			<div class="rightBox">
                <div class="title">按年份查询</div>
                <div class="list">
                    <div @click="clickYear(item)" :class="activeYear.categoryId == item.categoryId ? 'active' : ''" v-for="(item,index) in yearList" :key="item">{{item.name}}</div>
                </div>
            </div>
		</div>
		<!-- 底部 -->
        <footers :bg="'#EFF0F0'"></footers>
	</div>
</template>

<script>
import headers from '../components/header.vue'
import crumbs from '../components/crumbs.vue'
import footers from '../components/footers.vue'
import commonBanner from '../components/commonBanner.vue'
import {commomList,AboutcategoryList} from '../server/api'
export default {
	components:{
        headers,
        crumbs,
		footers,
		commonBanner
    },
	data(){
		return {
			listData:['首页','尚宁家园'],
			bannerObj:{
				cnTitle:'尚宁家园',
				enTitle:'SHANGNING HOMELAND',
				img:'banner1'
			},
			listDatas:[],

			// 当前选中的类型 1新闻 2学术 3客户书
            activeType:1,
            // 当前页码
            pageSize:6,
            // 分页条数
            pageNum:1,
            // 总条数
            totalNum:0,
            // 当前列表数据
            list:[],
            // 选中的年份
            activeYear:{},
            // 年份列表
            yearList:[],
		}
	},
	methods:{
		// 获取列表数据
		getListData(){
			commomList({
				CategoryId:this.activeYear.categoryId,
                pageSize:this.pageSize,
                pageNum:this.pageNum,
			}).then((res) => {
				console.log(res);
				this.listDatas = res.data.result;
				this.totalNum = res.data.totalNum;
			}).catch((err) => {
				
			});
		},
		// 点击月份
        clickYear(data){
            this.activeYear = data;
            this.pageNum = 1;
            this.totalNum = 0;
            this.getListData();
        },
        // 切换分页
        pageChange(pageNum){
            this.pageNum = pageNum;
            this.getListData();
        },

        // 获取月份列表
        getYueList(){
            return AboutcategoryList({
                ParentId:59
            }).then(res=>{
                console.log(res);
                this.yearList = res.data.result;
                // 设置默认选中年份
                this.activeYear = res.data.result[0];
            }).catch(err=>{
                console.log(err);
            })
        }
	},
	async created(){
		await this.getYueList();
		this.getListData();
	},
}
</script>

<style lang="less" scoped>
.snHome{
	width: 100%;
	min-height: 100vh;
	background-color: #EFF0F0;
	.ListBox{
		width: 100%;
		height: auto;
		padding: 0 147px;
		margin-top: 80px;
		margin-bottom: 180px;
		box-sizing: border-box;
		display: flex;
		align-items: flex-start;
		.leftBox{
			flex: 1;
			.items{
				width: 100%;
				height: auto;
				padding: 43px 39px;
				box-sizing: border-box;
				background-color: #fff;
				border-radius: 2px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				margin-top: 40px;
				&:nth-child(1){
					margin-top: 0;
				}
				.imgs{
					width: 42%;
					height: 84%;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.info{
					width: 48%;
					height: 100%;
					padding-top: 20px;
					.title{
						width: 100%;
						height: auto;
						font-size: 42px;
						color: #3E3E3E;
					}
					.text{
						width: 100%;
						height: auto;
						margin-top: 53px;
						font-size: 24px;
						color: #3E3E3E;
						line-height: 36px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 6;
						-webkit-box-orient: vertical;
					}
				}
			}
			.pages{
                width: 100%;
                height: auto;
                text-align: center;
                margin-top: 100px;
                font-size: 30px;
            }
		}
		.rightBox{
            width: 317px;
            height: auto;
            flex: none;
            margin-left: 68px;
			background-color: #fff;
            .title{
                width: 100%;
                height: 80px;
                padding-left: 42px;
                line-height: 80px;
                font-size: 26px;
                color: #FFFFFF;
                background-color: #006AB7;
                box-sizing: border-box;
            }
            .list{
                width: 100%;
                height: auto;
                padding: 50px 64px;
                box-sizing: border-box;
                // background-color: #EBF2F4;
                div{
                    font-size: 24px;
                    color: #3E3E3E;
                    margin-top: 37px;
                    position: relative;
                    cursor: pointer;
                    &:first-child{
                        margin-top: 0;
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #006AB7;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -23px;
                    }
                }
                .active{
                    color: #FDD000;
                    &::after{
                        background-color: #FDD000;
                    }
                }
            }
        }
	}
}
</style>